<template>
  <div class="container">
    <h1>Детали запроса</h1>
    <div v-if="order">
      <div class="order-detail">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="mb-3">
              <strong>Автомобиль: <br /> </strong>

              <b-link
                :to="'/orders/' + order._id + '/vehicle'"
                class="order-detail__car-name"
              >
                {{ order["vehicle"]["brand"] }} {{ order["vehicle"]["model"] }}
                {{ order["vehicle"]["year"] }}
              </b-link>
              <div
                class="order-detail__image mb-3"
                v-if="order['vehicle']['photo']"
              >
                <b-link :to="'/orders/' + order._id + '/vehicle'">
                  <img
                    :src="
                      'https://vp.bw2api.ru/' + order['vehicle']['photo']['url']
                    "
                    class="img-fluid"
                  />
                </b-link>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4">
            <p v-if="order.address">
              <strong>Адрес:</strong><br />
              {{ order.address }}
            </p>
            <p><strong>Регион:</strong> <br />{{ order.region }}</p>
            <div v-if="order.voice" class="mb-3">
              <p><strong>Аудио</strong><br /></p>
              <audio
                controls=""
                :src="'https://vp.bw2api.ru/' + order.voice['url']"
              ></audio>
              <p v-if="order.voiceText">
                <strong>Текст из аудио:</strong><br />
                {{ order.voiceText }}
              </p>
            </div>
            <p><strong>Описание запроса:</strong><br />{{ order.text }}</p>
            <p>
              <strong>Тип запчасти: <br /> </strong>
              <span v-if="order.itemType == 'New'">Новая запчасть</span>
              <span v-if="order.itemType == 'Old'">Контактная запчасть</span>
            </p>
          </div>
          <div class="col-12">
            <div
              class="gallery mb-3"
              v-if="order.photo1 || order.photo2 || order.photo3"
            >
              <p>
                <strong>Фото детали: <br /> </strong>
              </p>
              <div class="row">
                <div class="col-6 col-sm-auto" v-for="n in 3" :key="n">
                  <div class="gallery-item" v-if="order['photo' + n]">
                    <img
                      :src="'https://vp.bw2api.ru/' + order['photo' + n]['url']"
                      data-fancybox="gallery"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order-detail__date">
          Создан: {{ order.createdAt | moment("DD.MM.YY H:mm") }}
        </div>
      </div>

      <b-button
        v-b-toggle.responseForm
        variant="primary"
        @click="toggleResponse"
        class="mb-4"
      >
        Ответить на запрос
      </b-button>
      <b-collapse id="responseForm" class="create-offer">
        <b-form @submit.prevent="createResponse" @reset.prevent="onReset">
          <h3>Новый ответ</h3>
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <b-form-group label="Аудио" label-for="text">
                <MyRecorder @update="response.voice = $event" />
              </b-form-group>
            </div>
            <div class="col-md-6 col-lg-4">
              <b-form-group label="Текстовое примечание">
                <b-form-input
                  id="text"
                  v-model="response.text"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6 col-lg-4">
              <b-form-group label="Цена" label-for="price">
                <b-form-input
                  id="price"
                  v-model="response.price"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <b-form-group label="Фото детали">
            <div class="row">
              <div class="col-6 col-sm-auto" v-for="n in 3" :key="n">
                <label
                  class="btn-file"
                  v-bind:class="{ added: response['photo' + n]['file'] }"
                  :for="'fileField_' + n"
                >
                  <div
                    class="btn-file__preview"
                    :style="
                      'background-image: url(' +
                      response['photo' + n]['blob'] +
                      ');'
                    "
                  ></div>

                  <div class="btn-file__btns">
                    <b-button-group class="m-2" size="sm">
                      <b-button
                        title="Удалить"
                        variant="danger"
                        @click.prevent="response['photo' + n] = {}"
                      >
                        <b-icon icon="x"></b-icon>
                      </b-button>
                    </b-button-group>
                  </div>
                  <input
                    type="file"
                    :id="'fileField_' + n"
                    v-on:change="onFileChange($event, 'photo' + n)"
                  />
                </label>
              </div>
            </div>
          </b-form-group>

          <b-button type="submit" variant="primary" :disabled="formDisable"
            >Отправить</b-button
          >
        </b-form>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Fancybox } from "@fancyapps/ui";
import axios from "axios";
import MyRecorder from "../components/MyRecorder";

export default {
  name: "orders-details",
  data() {
    return {
      order: null,
      response: {
        text: "",
        photo1: {},
        photo2: {},
        photo3: {},
        price: "",
        voice: {},
      },

      formDisable: false,
    };
  },
  computed: {
    ...mapGetters(["profile"]),
  },
  components: { MyRecorder },
  async mounted() {
    this.order = await this.fetchOrder(this.$route.params["id"]);

    Fancybox.bind("[data-fancybox='gallery']", {});
  },
  methods: {
    ...mapActions(["fetchOrder", "fetchOffers"]),

    toggleResponse() {
      setTimeout(() => {
        document.querySelector("#responseForm").scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 300);
    },

    onFileChange(e, name) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.response[name] = {
        file: files[0],
        blob: window.URL.createObjectURL(files[0]),
      };
    },
    onReset(event) {
      this.response.text = "";
      this.response.photo1 = {};
      this.response.photo2 = {};
      this.response.photo3 = {};
      this.response.price = "";
      this.response.voice = {};
      this.formDisable = false;
    },
    async createResponse() {
      this.formDisable = true;
      let formData = new FormData();
      formData.append("state", "New");
      formData.append("creator", "null");
      formData.append("deliveryDuration", "");
      formData.append("contactPhone", "");
      formData.append("images", "null");
      formData.append("lat", "null");
      formData.append("long", "null");
      formData.append("address", "null");
      formData.append("price", this.response["price"]);
      formData.append("order", this.order["_id"]);
      formData.append("text", this.response["text"]);

      if (this.response["voice"]["blob"]) {
        formData.append("voice", this.response["voice"]["blob"], "audio.mp3");
      }

      if (this.response["photo1"]["file"]) {
        formData.append("photo1", this.response["photo1"]["file"]);
      }

      if (this.response["photo2"]["file"]) {
        formData.append("photo2", this.response["photo2"]["file"]);
      }

      if (this.response["photo3"]["file"]) {
        formData.append("photo3", this.response["photo3"]["file"]);
      }

      let response = await axios.post(
        "https://vp.bw2api.ru/api/v1/crud/offer",
        formData
      );

      if (response.status == 200) {
        this.onReset();

        this.$bvToast.toast(`Ваш ответ отправлен заказчику`, {
          title: "Отправлено",
          autoHideDelay: 5000,
          variant: "success",
        });

        this.$root.$emit("bv::toggle::collapse", "responseForm");

        await this.fetchOffers(this.profile);
      }
    },
  },
};
</script>
